import React, { FC, HTMLAttributes } from 'react';
import colors from 'tailwindcss/colors';
import themeConfig from '../../../config/theme.config';
import Logo from '../../../assets/arya-logo.svg'
type ILogoTemplateProps = HTMLAttributes<HTMLOrSVGElement>;
const LogoTemplate: FC<ILogoTemplateProps> = (props) => {
	const { ...rest } = props;
	return (

		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 198.43 176.06' {...rest}>
			<image xlinkHref={Logo} width='198.43' height='176.06' />
		</svg>
	);
};

export default LogoTemplate;
